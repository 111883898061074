
















































































import { WeatherEvent } from '@/store/event/definitions'
import { PropOptions } from 'vue'
import { AppRoute } from '@/store/app/definitions'

export default {
  props: {
    events: {
      type: Array,
      default: () => ([])
    } as PropOptions<WeatherEvent[]>,
    eventOver: {
      type: Object,
      default: null
    } as PropOptions<WeatherEvent>,
    eventSelected: {
      type: Object,
      default: null
    } as PropOptions<WeatherEvent>
  },
  data () {
    return {
      eventRoute: AppRoute.CLIMATERECAP,
      otherEventsToggleStatus: {}
    }
  },
  watch: {
    events: {
      handler () {
        // we initi otherEventsToggleStatus only if not already done
        if (Object.keys(this.otherEventsToggleStatus).length > 0) return

        this.otherEventsToggleStatus = {}
        this.events.forEach(e => {
          this.$set(this.otherEventsToggleStatus, e.id, false)
        })
      },
      immediate: true
    }
  }
}
