




















































































import { AppRoute } from '@/store/app/definitions'
import { WeatherEvent } from '@/store/event/definitions'
import { PropOptions } from 'vue'

export default {
  name: 'WorldEventsMap',
  props: {
    events: {
      type: Array,
      default: () => ([])
    } as PropOptions<WeatherEvent[]>,
    eventOver: {
      type: Object,
      default: null
    } as PropOptions<WeatherEvent>
  },
  data () {
    return {
      eventRoute: AppRoute.CLIMATERECAP
    }
  }
}
