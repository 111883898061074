



















































/* global VIEW_MAP_BACKGROUND_IMAGE */
import WorldEventsMap from '@/components/data/event/EventsMap.vue'
import EventsList from '@/components/data/event/EventsList.vue'
import Loader from '@/components/ui/Loader/Loader.vue'
import { vrsStore } from '@/store'

export default {
  components: {
    WorldEventsMap,
    EventsList,
    'ui-loader': Loader
  },
  data () {
    return {
      stateEvent: vrsStore.state.event,
      selectedSeason: null,
      eventOver: null,
      eventSelected: null,
      backgroundImage: VIEW_MAP_BACKGROUND_IMAGE
    }
  },
  computed: {
    eventsToDisplay () {
      if (!this.selectedSeason) return []
      return this.selectedSeason.events
    }
  },
  watch: {
    /**
     * When loading the event choice,
     * if no season is selected,
     * we select the one where there is the active event.
     * Could be the last one, or not :-)
     */
    'stateEvent.seasons': {
      handler (newValue) {
        if (!newValue || newValue.length < 1) return
        if (!this.selectedSeason) {
          let activeSeason = null
          let activeEvent = null
          vrsStore.state.event.seasons.forEach(currentSeason => {
            currentSeason.events.forEach(currentEvent => {
              if (currentEvent.active) {
                activeEvent = currentEvent
                activeSeason = currentSeason
              }
            })
          })
          activeSeason && (this.selectedSeason = activeSeason)
          activeEvent && (this.eventOver = activeEvent)
          activeEvent && (this.eventSelected = activeEvent)
        }
      },
      immediate: true
    }
  }
}
