var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events-list p-2"},_vm._l((_vm.events),function(event){return _c('div',{key:event.id,staticClass:"mb-1"},[_c('div',{staticClass:"flex items-center bg-white text-font-dark cursor-pointer",class:{
        'ml-1': !(event !== _vm.eventOver && event !== _vm.eventSelected),
        'ml-8': event !== _vm.eventOver && event !== _vm.eventSelected,
        'opacity-75': event.isInFuture && event !== _vm.eventOver && event !== _vm.eventSelected
      },on:{"mouseover":function($event){return _vm.$emit('mouseover', event)},"click":function($event){return _vm.$emit('click', event)}}},[_c('div',{staticClass:"p-1 pl-2 bg-primary w-16 text-white text-lg event-date h-100"},[_vm._v(" "+_vm._s(event.displayEndDate)),_c('br'),_vm._v(" "+_vm._s(event.displayEndMonth)+" ")]),_c('div',{staticClass:"flex-grow p-1"},[_c('div',{staticClass:"uppercase font-bold text-lg"},[_vm._v(" "+_vm._s(event.label)+" ")]),_c('div',[_vm._v(" "+_vm._s(event.period)+" ")])]),_c('div',{staticClass:"text-3xl mx-2"},[_vm._v(" "+_vm._s(event.countryUnicode)+" ")])]),(event === _vm.eventSelected)?_c('div',{staticClass:"bg-white text-font-dark ml-8"},[_c('div',{staticClass:"flex items-center justify-around py-2"},[_c('router-link',{staticClass:"hover:underline bg-primary-dark text-white rounded-full p-2 text-xl",attrs:{"to":{
            name: _vm.eventRoute,
            params: {
              eventId: event.id
            }
          }}},[_vm._v(" Go ")])],1),(event.seasons.length > 0)?[_c('h3',{staticClass:"p-2 cursor-pointer flex items-center",on:{"click":function($event){_vm.otherEventsToggleStatus[event.id] = !_vm.otherEventsToggleStatus[event.id]}}},[_c('span',{staticClass:"arrow transition-transform duration-200",class:{ collapsed: _vm.otherEventsToggleStatus[event.id] }}),_c('span',{staticClass:"ml-2"},[_vm._v("Other events (past & future)")])]),(_vm.otherEventsToggleStatus[event.id])?_c('div',_vm._l((event.seasons.filter(function (e) { return (!e.isInFuture); })),function(eventSeason){return _c('router-link',{key:eventSeason.id,staticClass:"block p-2 text-font-dark hover:underline",attrs:{"to":{
              name: _vm.eventRoute,
              params: {
                eventId: eventSeason.id
              }
            }}},[_c('strong',[_vm._v(_vm._s(eventSeason.year))]),_vm._v(" "+_vm._s(eventSeason.label)+" "),_c('i',[_vm._v(_vm._s(eventSeason.period))])])}),1):_vm._e()]:_vm._e()],2):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }